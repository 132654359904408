import cx from "classnames";
import { Link } from "gatsby";
import React, { FC, useEffect, useRef, useState } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import SEO from "../components/seo";
import * as global from "../styles/global.module.scss";
import * as styles from "../styles/pages/a-propos.module.scss";
import { eventDispatcher } from "../utils/eventDispatcher";
import { sendGA } from "../utils/helpers";
const { routes } = require("../routes");

interface Props {
  location: Location;
}

const AProposPage: FC<Props> = (props: Props) => {
  const { location } = props;
  const $bg = useRef<HTMLDivElement>(null);
  const [showBg, setShowBg] = useState(false);
  const onClickDoNotClick = () => {
    eventDispatcher.trigger("Click:DoNotClick", { disableBg: !showBg });
    setShowBg(!showBg);
    sendGA("click", {
      event_label: "Cta - Do not click",
    });
  };
  useEffect(() => {
    return () => {
      eventDispatcher.trigger("Click:DoNotClick", { disableBg: false });
    };
  }, []);
  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - Qui se cache derrière ?"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={styles.presentationContainer}>
          <h2 className={cx(styles.title, global.title)}>
            Qui se cache derrière Constant&nbsp;?
          </h2>
          <div className={cx(styles.text, global.text)}>
            <p>
              Derrière Constant, il y a moi,{" "}
              <span className={styles.doNotCLickContainer}>
                <span className={styles.doNotCLick} onClick={onClickDoNotClick}>
                  <strong>Rémy</strong>
                </span>
                <span className={styles.warning}>
                  <BsArrowReturnRight /> Ne pas cliquer
                </span>
              </span>
              .
            </p>

            <p>
              De base je suis développeur web et{" "}
              <strong>
                j'ai lancé Constant car c'est un projet qui me ressemble
              </strong>
              . ⁠ Avant, j'avais une marketplace de mode éthique. C'était un
              projet vraiment cool qui m'a permis de découvrir de nombreuses
              marques et de rencontrer plein de personnes.⁠
              <br />
              Par contre il y a un aspect qui me posait problème&nbsp;: Le but
              d'une marketplace est de pousser à la consommation pour gagner
              assez de €€ pour grossir et vendre plus.⁠
            </p>

            <p>
              <strong>
                Je ne suis pas quelqu'un qui achète énormément de vêtements
              </strong>
              , plutôt tout le contraire même. Je n’achète pratiquement rien et
              je mets énormément de temps à me motiver.⁠ Du coup, avoir un site
              dont le but est d'inciter les gens à consommer ne me ressemblait
              pas de fou. Cela paraît évident maintenant, mais vu que j'étais
              pris dans l’effervescence du projet, le réaliser m'a quand même
              demandé 1 an...⁠
            </p>

            <p>
              A part ça, tout le reste me convenait. J'aime découvrir de
              nouvelles marques, m'informer sur la mode éthique et rencontrer
              les personnes de ce milieu, qui sont toujours très
              interessantes&nbsp;!⁠ Et en plus, j'ai un petit défaut. Vu que je
              n’achète pratiquement rien,{" "}
              <strong>
                j'aime avoir des vêtements de marques que personne d'autre ne va
                connaitre
              </strong>
              , pour pouvoir faire le mec...
              <br />
              Ce n'est pas forcément hyper sain comme état d'esprit (même si on
              est tous un peu comme ça...), mais l'avantage c'est que{" "}
              <strong>
                la mode éthique est une source intarissable de nouvelles marques
              </strong>{" "}
              car elle est composée de plein de petits et moyens
              créateur.ice.s&nbsp;!⁠
            </p>

            <p>
              <strong>D’où Constant&nbsp;!</strong> Grâce à ce service{" "}
              <strong>je peux vous faire découvrir des marques</strong> qui vous
              intéresseront et que vous ne connaissez pas,{" "}
              <strong>sans vous inciter à acheter</strong>&nbsp;!⁠
              <br />
              <Link to={routes.app.guidePerso.path} className={global.link}>
                Et de votre côté, vous pourrez les faire découvrir autour de
                vous et aider la mode éthique à se démocratiser tout en étant LA
                personne cool qui connait les vraies marques.
              </Link>
            </p>

            <p>
              <strong>
                C'est une bonne manière d'utiliser ses défauts vous ne trouvez
                pas&nbsp;?⁠
              </strong>
            </p>

            <p>
              N’hésitez pas à me contacter si vous avez des questions&nbsp;:
              <br />
              <a
                href={`mailto:${process.env.GATSBY_EMAIL}`}
                className={global.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {process.env.GATSBY_EMAIL}
              </a>
            </p>
          </div>
        </div>
      </div>
      {showBg ? <div className={styles.remyBackground} ref={$bg}></div> : null}
    </>
  );
};

export default AProposPage;
